.HomeBanner {
  position: relative;
}
.backgroundBanner {
  width: 100%;
  /* height: 100vh; */
  /* background-color: brown; */
  /* background: linear-gradient(
      90deg,
      rgba(17, 138, 138, 1) 0%,
      rgba(17, 138, 138, 0.8) 50%
    ); */

  & img {
    width: 100%;
  }
}

.bannerTexts {
  position: absolute;
  top: 50%;
  left: 30%;
  transform: translate(-50%, -50%);
  width: 750px;
}
.bannertagLine {
  font-size: 36px;
  font-weight: 700;
  color: #ffffff;
  line-height: 1.4;
}

.bannerpara {
  font-size: 24px;
  font-weight: 400;
  color: #ffffff;
  line-height: 1.4;

  & span {
    font-weight: 500;
    color: #032c6b;
  }
}

.showmobile {
  display: none;
}
.flex {
  display: flex !important;
  align-items: center;
  justify-content: center;
}

.mainBannerContainer {
  min-height: 620px;
}
.banneronecolor {
  background: linear-gradient(
    270deg,
    rgba(17, 138, 138, 0.5) 0%,
    rgba(17, 138, 138, 0) 297.6%
  );
}
.bannercolortwo {
  background-color: #e8ecf1;
}
.bannercolorthree {
  background-color: #faf2f2;
  /* justify-content: space-around; */
}
.bannercolorfour {
  background-color: #d5e6d4;
  gap: 40px;
}
.textcontainer {
  width: 45%;
}
.textcenter {
  text-align: center;
}
.imgcontainer {
  width: 40%;

  & img {
    width: 100%;
    margin: 0 auto;
  }
}

.leftsideContainer {
  width: 35%;
}
.videocontainer {
  /* width: 35%; */
  border-radius: 25px;
  overflow: hidden;
}
.videocontainer2 {
  width: 50%;
  border-radius: 25px;
  overflow: hidden;
  margin: auto;
  height: 550px;
}
.innerVideoDisplay {
  border-radius: 25px;
}
.marginauto {
  margin: 25px auto 0;
}
.bannertwopurple {
  color: #291a75 !important;
}
.bannertwopara {
  color: #756ab2 !important;
}

.btnGradient {
  background: linear-gradient(
    90deg,
    rgba(17, 137, 138, 1) 0%,
    rgba(44, 91, 150, 1) 100%
  );
  color: #ffffff !important;
  font-size: 16px !important;
  font-weight: 700;
}

.gradiantfont {
  background: -webkit-linear-gradient(#286394, #15838c);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.textblue {
  color: #032c6b;
  font-size: 24px;
  font-weight: 400;
}
.margintop40 {
  margin-top: auto;
}
.imgcontainerforiit {
  width: 40%;
  & img {
    margin: 0 auto;
  }
}

.imgcontainerstem {
  width: 40%;

  & img {
    margin: 0 auto 10px;
    width: 100%;
  }
}
.videoModel {
  width: 100%;
}

.ViewMoreButton {
  margin-top: 50px;
  /* background: transparent; */
  border: none;
  font-size: 20px;
  border-radius: 5px;
  color: #fff;
  padding: 10px 10px;
  background: linear-gradient(
    97.14deg,
    #11888a -3.11%,
    #2a5e95 100%
  ) !important;
}
.buttoncontainers {
  display: flex;
  gap: 20px;
  /* align-items: center; */
  /* justify-content: center; */
}

.freeTest {
  background: linear-gradient(97.14deg, #11888a -3.11%, #2a5e95 100%);
  text-decoration: none;
  color: #fff;
  padding: 5px 15px;
  border-radius: 5px;
  font-size: 20px;
  display: flex;
  align-items: center;
  gap: 5px;
}

@media only screen and (max-width: 1440px) and (min-width: 998px) {
  .imgcontainer {
    width: 50%;

    & img {
      width: 100%;
    }
  }
  .margintop40 {
    margin-top: auto;
  }
}
@media only screen and (max-width: 1024px) and (min-width: 768px) {
  .imgcontainer {
    width: 50%;

    & img {
      width: 100%;
    }
  }
  .margintop40 {
    margin-top: auto;
  }
  .flexcolume {
    flex-direction: column;
    text-align: center;
  }
  .width75 {
    width: 75%;
  }
}

@media only screen and (max-width: 767px) and (min-width: 320px) {
  .bannerTexts {
    /* position: static; */
    width: 100%;
    transform: none;
    top: 5%;
    left: 5%;
  }
  .bannercolorfour {
    background-color: #d5e6d4;
    gap: 0;
  }
  .bannertagLine {
    font-size: 24px;
    font-weight: 700;
  }
  .bannerpara {
    font-size: 16px;
    font-weight: 400;
  }

  .hidemobile {
    display: none;
  }
  .showmobile {
    display: block;
  }
  .flex {
    flex-direction: column;
    justify-content: space-between;
  }
  .textcontainer {
    width: 100%;
    text-align: center;
  }
  .mainBannerContainer {
    min-height: 600px;
  }
  .imgcontainer {
    width: 85%;

    & img {
      width: 100%;
    }
  }
  .imgcontainerforiit {
    width: 50%;
    & img {
      width: 100%;
    }
  }

  .imgcontainerstem {
    width: 95%;

    & img {
      width: 100%;
    }
  }
  .leftsideContainer {
    width: 100%;
    text-align: center;
  }
  .videocontainer {
    width: 90%;
    height: 250px;
    border-radius: 25px;
    overflow: hidden;

    margin: 10px auto 15px;
  }
  .videocontainer2 {
    width: 45%;
    border-radius: 25px;
    overflow: hidden;
    margin: auto;
    height: 290px;
  }
  .bannercolortwo {
    display: flex !important;
    flex-direction: column;
    justify-content: space-around;
  }
  .marginauto {
    margin: 100px auto 0;
  }

  .boxForMobile {
    width: 300px !important;
  }
  .buttoncontainers {
    justify-content: center;
  }
  .ViewMoreButton {
    text-align: center;
    margin: 0 auto;
    margin-top: 0px;
    margin-bottom: 10px;
    /* background: transparent; */
    border: none;
    font-size: 20px;
    border-radius: 5px;
    color: #fff;
    padding: 10px 10px;
    background: linear-gradient(
      97.14deg,
      #11888a -3.11%,
      #2a5e95 100%
    ) !important;
  }
}

@media only screen and (max-width: 450px) {
  .imgcontainerstem {
    width: 90%;

    & img {
      margin-bottom: 20px;
      width: 100%;
    }
  }
}
